import { createGlobalStyle, css } from "styled-components";
import { fonts } from "../styles/fonts";

const globalCss = (bgColor: string) => css`
  [data-netlify-deploy-id] {
    display: none;
  }
  [data-gatsby-image-wrapper] {
    border-radius: 8px;
  }
  [data-main-image] {
    transition: none !important;
  }
  * {
    box-sizing: border-box;
  }
  html {
    margin: 0;
  }
  body {
    margin: 0;
    font-family: ${fonts.gtFlexaMono};
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    background: ${bgColor};
  }
  img {
    max-width: 100%;
  }
  svg {
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: normal !important;
    margin: 0;
  }
  .page-lock {
    overflow: hidden;
  }
`;

export const GlobalStyle = createGlobalStyle<{ bgColor?: string }>`
  ${({ bgColor }) => globalCss(bgColor ?? "white")};
`;
