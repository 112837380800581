import { useContext, useState, useEffect, createRef, RefObject } from 'react'
import { NavContext } from '../contexts/NavContext'
import { NavEffect } from '../typings/modules'

type NavRefEffect = {
  navRef: RefObject<HTMLDivElement>
  navEffect: NavEffect | undefined
}

export const useNavEffect = (navId: string): NavRefEffect => {
  const navRef = createRef<HTMLDivElement>()
  const { navTargets, setNavTargets, navEffects } = useContext(NavContext)
  const [navEffect, setNavEffect] = useState<NavEffect>()
  useEffect(() => {
    if (navRef?.current) {
      if (!navTargets.find(navTarget => navTarget?.id === navId)) {
        const newNavTarget = { id: navId, target: navRef.current }
        setNavTargets([...navTargets, newNavTarget])
      }
    }
  }, [navRef])
  useEffect(() => {
    const newNavEffect = navEffects.find(item => item?.id === navId)
    setNavEffect(newNavEffect)
  }, [navEffects])
  return { navRef, navEffect }
}
