const mq = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

export const mqUtil = (mediaQuery: string) =>
  mediaQuery.includes("@media")
    ? mediaQuery.replace("@media", "").trim()
    : mediaQuery;

export const mqs = {
  custom: mq,
  sm: mq(576),
  md: mq(768),
  lg: mq(1024),
  xl: mq(1200),
};
