import { useEffect, useContext, useCallback, createRef } from 'react'
import { SectionContext } from '../contexts/SectionContext'
import { ThemeType } from '../typings/modules'

export const useBlockRef = (item: { id: string, theme: ThemeType }) => {
  const { compList, setCompList } = useContext(SectionContext)
  const blockRef = createRef<HTMLDivElement>()
  const bindBlock = useCallback(() => {
    if (blockRef?.current) {
      const com = compList ?? []
      const foundItem = com?.find(compItem => compItem.id == item.id)
      if (!foundItem) {
        const { id } = item
        const theme = item.theme
        const newCompItem = { id, target: blockRef.current, theme }
        setCompList([...com, newCompItem])
      }
    }
  }, [blockRef])
  useEffect(bindBlock, [bindBlock])
  return blockRef
}
