import React from 'react'
import styled from 'styled-components'
import BeeVideo from '../assets/videos/hunni_video_1440_opt.mp4'
// import ChevronDownIcon from '../assets/svgs/icon-chevron-down.svg'
import { useBlockRef } from '../hooks/useBlockRef'
// import { Heading, Copy } from '../components/Typography'
import { Button, DefaultButton } from './Ui'
import { mqs } from '../styles/mq'

const Container = styled.div`
  height: 100vh;
  display: grid;
  width: 75vw;
  margin: auto;
  position: relative;
`
const FiftyFifty = styled.div`
  @media (min-width: 786px) {
    display: grid;
    grid-gap: 6.25vw;
    align-items: center;
    grid-template-columns: 1.2fr 1fr;
  }
`
const VideoPane = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
`
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 720px;
  ${mqs.md} {
    width: 75%;
  }
  ${mqs.lg} {
    width: 50%;
  }
`
const Video = styled.video`
  border-radius: 8px;
`
const Content = styled.div`
  color: white;
  p {
    margin-top: 10px;
    a {
      color: #64ff00;
    }
  }
`

const Down = styled(DefaultButton)`
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  margin: auto;
  svg {
    margin: auto;
  }
`

const Email = styled.p`
  text-align: center;
  padding-top: 80px;
`

export const Home = () => {
  const blockRef = useBlockRef({ id: 'intro', theme: 'light' })
  return (
    <Container ref={blockRef}>
      <VideoContainer>
        <Video width="100%" preload="auto" autoPlay muted loop playsInline>
          <source src={BeeVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </Video>
        <Email>d@hunni.dev</Email>
        {/* <Button>
              <span>Play</span>
            </Button> */}
      </VideoContainer>
      {/* <FiftyFifty>
        <VideoPane>

        </VideoPane>
        <Content>
          <Heading>
            Conscious web development, for brands designing
            <br />a better future.
          </Heading>
          <Copy>
            Hunni Dev is a small studio of design-minded senior developers,
            partnering with innovative founders, early-stage startups and
            industry leaders to translate their vision into impactful digital
            products. Based in Brooklyn, NY.{' '}
            <a href="mailto:d@hunni.dev">d@hunni.dev</a>
          </Copy>
        </Content>
      </FiftyFifty> */}
      {/* <Down>
        <ChevronDownIcon />
      </Down> */}
    </Container>
  )
}
