import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  sortByTop,
  calcCompBlock,
  calcNavSize,
  calcNavEffect,
  vertWinFilter,
} from "../utils/navUtils";
import { NavTarget, NavEffect, ThemeType } from "../typings/modules";
import { SectionContext } from "./SectionContext";

type NavContextProps = {
  navTargets: NavTarget[];
  setNavTargets: Dispatch<SetStateAction<NavTarget[]>>;
  navEffects: NavEffect[];
};

export const NavContext = createContext<NavContextProps>({} as NavContextProps);

type NavProviderProps = { children: ReactNode };

export const NavProvider = ({ children }: NavProviderProps) => {
  const { compList } = useContext(SectionContext);
  const [navTargets, setNavTargets] = useState<NavTarget[]>([]);
  const [navEffects, setNavEffects] = useState<NavEffect[]>([]);
  const onChange = useCallback(() => {
    const { pageYOffset: winOffset, innerHeight: winHeight } = window;
    const blocks = compList.map(calcCompBlock).sort(sortByTop);
    const navBlocks = blocks.filter(vertWinFilter(winOffset, winHeight));
    const newEffects = navTargets
      .map(calcNavSize)
      .map(calcNavEffect(navBlocks));
    setNavEffects(newEffects);
  }, [compList, navTargets]);
  useEffect(() => {
    onChange();
    window.addEventListener("scroll", onChange);
    window.addEventListener("resize", onChange);
    return () => {
      window.removeEventListener("scroll", onChange);
      window.removeEventListener("resize", onChange);
    };
  }, [onChange]);
  return (
    <NavContext.Provider
      value={{
        navTargets,
        setNavTargets,
        navEffects,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const NavConsumer = NavContext.Consumer;
