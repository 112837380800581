import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import styled, { css } from 'styled-components'
import { useNavEffect } from '../hooks/useNavEffect'
import { ThemeType } from '../typings/modules'
import { calcClip } from '../utils/navUtils'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import DrawSVGPlugin from '../vendor/DrawSVGPlugin'

const svgDimensions = css`
  width: 80px;
  height: 25px;
  @media (min-width: 1024px) {
    width: 100px;
    height: 30px;
  }
  @media (min-width: 1220px) {
    width: 106px;
    height: 32px;
  }
`

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: calc(3.125vw + 18px);
  transform: translateY(-50%);
  margin: auto;
  z-index: 3;
  ${svgDimensions};
`

const EffectLogo = styled.div<{
  baseTheme: ThemeType
  currTheme: ThemeType
}>`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  ${svgDimensions};
`

const Svg = styled.svg`
  ${svgDimensions};
`

export const Logo = () => {
  const [ready, setReady] = useState(false)
  const { navRef, navEffect } = useNavEffect('logo')
  const themes = ['light']
  useEffect(() => {
    if (navEffect) {
      gsap.registerPlugin(MotionPathPlugin, DrawSVGPlugin)
      setReady(true)
    }
  }, [navEffect])
  return (
    <Container ref={navRef}>
      {navEffect &&
        themes.map((theme) => (
          <LogoEffect
            key={`logo-${theme}`}
            navEffect={navEffect}
            theme={theme}
            ready={ready}
          />
        ))}
    </Container>
  )
}

const LogoEffect = ({ ready, theme, navEffect }: any) => {
  const motionPathRef = useRef<SVGPathElement | null>(null)
  const dotPathRef = useRef<SVGPathElement | null>(null)
  const dotRef = useRef<SVGCircleElement | null>(null)
  useEffect(() => {
    if (
      ready &&
      motionPathRef.current &&
      dotPathRef.current &&
      dotRef.current
    ) {
      const dotConfig = {
        motionPath: {
          path: dotPathRef.current,
          align: dotPathRef.current,
          alignOrigin: [0.5, 0.5],
        },
      }
      ;(gsap as any)
        .timeline({ defaults: { duration: 1.5, ease: 'power3.out' } })
        .from(dotRef.current, {})
        .to(dotRef.current, dotConfig, '<')
        .from(motionPathRef.current, { drawSVG: 0 }, '<')
    }
  }, [ready])
  return ready ? (
    <EffectLogo
      baseTheme={theme}
      currTheme={navEffect.currTheme}
      style={{ clip: calcClip(theme, navEffect) }}
    >
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 32">
        <defs>
          <mask id="mask" maskUnits="userSpaceOnUse">
            <path
              ref={motionPathRef}
              d="M2.1 11.8C8.5 5.4 12.5 2.2 14.1 2.2 16.5 2.2 9.2 30 11.6 30 14 30 23.6 15.6 26 15.6 28.4 15.6 23.6 30 26 30 28.4 30 38 15.6 40.4 15.6 42.8 15.6 38 30 40.4 30 42.8 30 52.4 15.6 54.8 15.6 57.2 15.6 52.4 30 54.8 30 57.2 30 66.8 15.6 69.2 15.6 71.6 15.6 67.2 30 69.3 30 71.4 30 81.3 15.6 83.7 15.6 86.1 15.6 81.3 30 83.7 30 85.3 30 90.1 25.2 98.1 15.6"
              stroke={theme === 'light' ? '#fff' : '#000'}
              strokeWidth="3.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </mask>
        </defs>
        <g mask="url(#mask)" fill="none">
          <path
            ref={dotPathRef}
            d="M2.1 11.8C8.5 5.4 12.5 2.2 14.1 2.2 16.5 2.2 9.2 30 11.6 30 14 30 23.6 15.6 26 15.6 28.4 15.6 23.6 30 26 30 28.4 30 38 15.6 40.4 15.6 42.8 15.6 38 30 40.4 30 42.8 30 52.4 15.6 54.8 15.6 57.2 15.6 52.4 30 54.8 30 57.2 30 66.8 15.6 69.2 15.6 71.6 15.6 67.2 30 69.3 30 71.4 30 81.3 15.6 83.7 15.6 86.1 15.6 81.3 30 83.7 30 85.3 30 91.9 22.9 103.5 8.8"
            stroke={theme === 'light' ? '#fff' : '#000'}
            strokeWidth="3.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <circle
          ref={dotRef}
          fill={theme === 'light' ? '#fff' : '#000'}
          cx="103.5"
          cy="8.8"
          r="2.4"
        ></circle>
      </Svg>
    </EffectLogo>
  ) : null
}
