import styled from "styled-components";
import { mqs } from "../styles/mq";

export const FiftyFifty = styled.div<{ mediaLeft?: boolean }>`
  @media (min-width: 786px) {
    display: grid;
    grid-gap: 6.25vw;
    align-items: center;
    grid-template-columns: ${({ mediaLeft }) =>
      mediaLeft ? "1.2fr 1fr" : "1fr 1.2fr"};
  }
`;

export const Spacer = styled.span<{ x?: string[]; y?: string[] }>`
  display: block;
  ${({ y }) => y && `padding-top: ${y[0]}`};
  ${({ x }) => x && `padding-left: ${x[0]}`};
  ${mqs.md} {
    ${({ y }) => y && `padding-top: ${y[1]}`};
    ${({ x }) => x && `padding-left: ${x[1]}`};
  }
  ${mqs.lg} {
    ${({ y }) => y && `padding-top: ${y[2]}`};
    ${({ x }) => x && `padding-left: ${x[2]}`};
  }
`;

export const Button = styled.button`
  background: #7200ff;
  border: 0;
  width: 128px;
  height: 64px;
  border-radius: 32px;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  cursor: pointer;
  font: inherit;
  font-size: 14px;
  span {
    position: relative;
    left: 2px;
  }
`;

export const DefaultButton = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  appearance: none;
  font: inherit;
  cursor: pointer;
`;
