import React, { PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import { GlobalStyle } from './GlobalStyle'
import { SectionProvider } from '../contexts/SectionContext'
import { NavProvider } from '../contexts/NavContext'
import { Logo } from '../components/Logo'
import { Menu } from '../components/Menu'
import { HiveLink } from '../components/HiveLink'

const Main = styled.main`
  display: grid;
`

export const Page = ({ children }: PropsWithChildren<{}>) => {
  useEffect(() => {
    const loadPolyfill = async () => {
      if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer' as any)
      }
    }
    loadPolyfill()
  }, [])
  return (
    <SectionProvider>
      <NavProvider>
        <GlobalStyle />
        <Logo />
        {/* <Menu /> */}
        {/* <HiveLink /> */}
        <Main>{children}</Main>
      </NavProvider>
    </SectionProvider>
  )
}
