import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import { CompItem } from "../typings/modules";

type SectionProviderProps = { children: ReactNode };

type SectionContextProps = {
  compList: CompItem[];
  setCompList: Dispatch<SetStateAction<CompItem[]>>;
};

export const SectionContext = createContext<SectionContextProps>(
  {} as SectionContextProps
);

export const SectionProvider = ({ children }: SectionProviderProps) => {
  const [compList, setCompList] = useState<CompItem[]>([]);
  return (
    <SectionContext.Provider
      value={{
        compList,
        setCompList,
      }}
    >
      {children}
    </SectionContext.Provider>
  );
};

export const SectionConsumer = SectionContext.Consumer;
