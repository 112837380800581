import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Page } from '../components/Page'
import { Home } from '../components/Home'
// import { Services } from '../components/Services'
// import { Partners } from '../components/Partners'
// import { Projects } from '../components/Projects'
// import { Principles } from '../components/Principles'
// import { Contact } from '../components/Contact'
// import { LazyMotion, m } from 'framer-motion'

// const loadFeatures = () =>
//   import('../features/features').then((res) => res.default)

const SectionContainer = styled.section<{ bgColor: string; color: string }>`
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`

const sections = [
  {
    name: 'home',
    Component: Home,
    bgColor: 'black',
    color: 'white',
  },
  // {
  //   name: 'services',
  //   Component: Services,
  //   bgColor: 'white',
  //   color: 'black',
  // },
  // {
  //   name: 'projects',
  //   Component: Projects,
  //   bgColor: '#f4f4f4',
  //   color: 'black',
  // },
  // {
  //   name: 'principles',
  //   Component: Principles,
  //   bgColor: 'white',
  //   color: 'black',
  // },
  // {
  //   name: 'partners',
  //   Component: Partners,
  //   bgColor: '#f4f4f4',
  //   color: 'black',
  // },
  // {
  //   name: 'contact',
  //   Component: Contact,
  //   bgColor: 'black',
  //   color: 'white',
  // },
]

const IndexPage = ({ transitionStatus, entry, exit }: any) => {
  return (
    <>
      <Helmet>
        <title>Hunni Dev | Thoughful web development</title>
        <meta
          name="description"
          content="Partnering with innovative founders, early-stage startups and industry leaders to create impactful digital products. We build websites that last."
        />
      </Helmet>
      <Page>
        {sections.map(({ name, Component, bgColor, color }) => (
          <SectionContainer
            key={name}
            id={name}
            bgColor={bgColor}
            color={color}
          >
            <Component />
          </SectionContainer>
        ))}
      </Page>
    </>
  )
}

export default IndexPage
