import {
  ThemeType,
  CompItem,
  CompBlock,
  NavTarget,
  NavEffect,
} from '../typings/modules'

export const sortByTop = (a: CompBlock, b: CompBlock) =>
  a.top > b.top ? 1 : -1

export const vertWinFilter = (winOffset: number, winHeight: number) => ({
  top,
  height,
}: CompBlock) => top + height + winOffset >= winOffset && top < winHeight

export const calcCompBlock = ({ id, target, theme }: CompItem): CompBlock => {
  const { top, height } = target.getBoundingClientRect()
  return { id, top, height, theme }
}

export const calcNavSize = ({ id, target }: NavTarget): NavEffect => {
  const { top, height } = target.getBoundingClientRect()
  const offset = height
  const currTheme = 'dark'
  const nextTheme = 'dark'
  return { id, top, height, offset, currTheme, nextTheme }
}

export const vertBlockFilter = (navEffect: NavEffect) => ({
  top,
  height,
}: CompBlock) => top + height > navEffect.top

export const calcNavEffect = (navBlocks: CompBlock[]) => (
  navEffect: NavEffect,
): NavEffect => {
  const [currBlock, nextBlock] = navBlocks.filter(vertBlockFilter(navEffect))
  if (currBlock && nextBlock) {
    const diff = nextBlock.top - navEffect.top
    const offset = Math.min(Math.max(diff, 0), navEffect.height)
    const currTheme = diff <= 0 ? nextBlock.theme : currBlock.theme
    const nextTheme = nextBlock.theme
    return { ...navEffect, offset, currTheme, nextTheme }
  } else if (currBlock) {
    const offset = navEffect.height
    const currTheme = currBlock.theme
    const nextTheme = currBlock.theme
    return { ...navEffect, offset, currTheme, nextTheme }
  }
  return navEffect
}

export const calcClip = (theme: ThemeType, navEffect: NavEffect) => {
  const { currTheme, nextTheme, offset, height } = navEffect
  if (offset === height) {
    if (currTheme !== theme) {
      return `rect(${height}px, 113px, ${height}px, 0px)`;
    }
    if (nextTheme === 'light') {
      return `rect(0px, 113px, ${height}px, 0px)`;
    } else {
      return `rect(0px, 113px, ${offset}px, 0px)`;
    }
  }
  if (theme === 'light') {
    if (nextTheme === 'dark') {
      return `rect(0px, 113px, ${offset}px, 0px)`;
    } else {
      return `rect(0px, 113px, ${height}px, 0px)`;
    }
  } else {
    if (nextTheme === 'dark') {
      return `rect(${currTheme === 'dark' ? 0 : offset}px, 113px, ${height}px, 0px)`;
    } else {
      return `rect(0px, 113px, ${currTheme === 'light' ? 0 : offset}px, 0px)`;
    }
  }
}
